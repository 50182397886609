<template>
    <div>
        <span class="pr-1">{{ willItPlay ? 'YES' : 'NO' }}</span>
        <v-icon v-if="willItPlay" color="green">mdi-check-circle</v-icon>
        <v-icon v-else color="red">mdi-close-circle</v-icon>
    </div>
</template>

<script>
export default {
    name: "WillItPlay",
    props: {
        willItPlay: {
            type: Boolean,
            required: true
        }
    }
}
</script>
